export default function addYandexMetrika(): void {
    if (process.env.REACT_APP_ENV !== 'prod') {
        return;
    }

    const yandexMetrika =
        '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");ym(50830897, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});';

    const yandexScript = document.createElement('script');

    yandexScript.innerHTML = yandexMetrika;

    document.body.appendChild(yandexScript);
}
